<template>
  <div class="rewards">
    <ul class="tight">
      <li v-for="(item, idx) in state.items" :key="idx">
        <div class="wrapper">
          <div class="relative">
            <span class="count">{{ $lib.getNumberFormat(item.joinCount) }}명 후원</span>
            <span class="count ml-1" v-if="item.rewardQty > 0 && item.extraCount > 0">{{ $lib.getNumberFormat(item.extraCount) }}개 남음</span>
            <div class="amount font-xl bold">
              <span v-if="item.rewardSeq">{{ $lib.getNumberFormat(item.rewardAmt) }}원 펀딩</span>
              <span v-else>리워드 없이 참여</span>
            </div>
            <div class="title" v-html="item.title"></div>
            <div class="delivery font-xs" v-if="item.rewardExpectText">배송 예정일 : {{ item.rewardExpectText }}</div>
            <template v-if="(!item.rewardSeq || Number(item.rewardQty) === 0 || item.extraCount > 0)">
              <div class="act">
                <router-link :to="`/reward/${$route.params.projectSeq}/order?rewardSeq=${item.rewardSeq || ''}`" class="btn btn-bordered-point font-sm">
                  <span>후원하기</span>
                </router-link>
              </div>
            </template>
            <div class="alert alert-warning font-sm" v-else>리워드가 모두 펀딩되었습니다.</div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import {defineComponent, reactive} from "@vue/composition-api";
import store from "@/scripts/store";
import mixin from "@/scripts/mixin";
import track from "@/scripts/track";

function Component(initialize) {
  this.name = "modalRewards";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  setup() {
    const component = new Component(() => {
      store.commit("tuneModal", {component, size: "sm"});

      if (Array.isArray(modalParams?.items)) {
        state.items = modalParams.items;
      }

      track.post({
        name: `rewardProjectRewards`,
        category: "후원하기",
        topic: "리워드 목록 모달",
        title: modalParams?.projectName,
        type: "modal",
      });
    });

    const state = reactive({
      items: []
    });

    const modalParams = store.getters.modalParams(component);

    return {component, state};
  }
});
</script>

<style lang="scss" scoped>
.rewards {
  ul {
    li {
      .wrapper {
        padding: $px28 $px25;
        background: #fff;
        border-bottom: $px1 solid #eee;

        .count {
          display: inline-block;
          font-size: 70%;
          background: $colorSecondary;
          color: #fff;
          padding: $px1 $px5;
          margin-bottom: $px5;
          vertical-align: middle;
          border-radius: $px2;

          span {
            vertical-align: middle;
          }
        }

        .amount {
          margin-bottom: $px10;
        }

        .delivery {
          color: #666;
          margin-top: $px15;
        }

        .act {
          position: absolute;
          top: 0;
          right: 0;

          a {
            padding: $px15 $px23;
            border: $px1 solid;

            span {
              display: inline-block;
              vertical-align: middle;
            }
          }
        }

        .alert {
          margin: $px15 0 0 0;
        }
      }

      &:last-child {
        margin-bottom: 0;

        .wrapper {
          border-bottom: 0;
        }
      }
    }
  }
}
</style>